import React from "react";
import styled, { css } from "styled-components";
import familyTreeRainbow from "../images/family-tree-single-rainbow.svg";
import Heading from "../components/Heading";
import Text from "../components/Text";
import { postMessage } from "../utils/postmessage";

const Container = styled.div`
  width: 100vw;
  height: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${familyTreeRainbow});
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: 50% 55%;
  position: relative;
`;

const StyledHeading = styled(Heading.H1)`
  position: absolute;
  bottom: 10vw;
  line-height: 30vw;
  z-index: 500;
`;

const FamilyText = styled(Text)`
  white-space: pre-line;
`;

const FamilyItem = styled.div`
  position: absolute;
  width: 15vw;
  height: 10vw;
  background-color: rgba(0, 0, 0, 0);
  transform-origin: 100% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  ${(props) =>
    props.left &&
    css`
      left: ${props.left}vw;
    `}
  ${(props) =>
    props.right &&
    css`
      right: ${props.right}vw;
    `}
  ${(props) =>
    props.top &&
    css`
      top: ${props.top}vw;
    `}
  ${(props) =>
    props.bottom &&
    css`
      bottom: ${props.bottom}vw;
    `}
  ${(props) =>
    props.rotate &&
    css`
      transform: rotate(${props.rotate}deg);
    `}
`;

const FamilyTree = ({ customQuestions }) => {
  const { items } = customQuestions;
  items.sort((a, b) => (a.position > b.position ? 1 : -1));
  return (
    <Container>
      <FamilyItem rotate={-70} bottom={40} left={-5} onClick={() => postMessage(items?.[0])}>
        <FamilyText tac>{items?.[0]?.answers?.items?.[0]?.text || items?.[0]?.placeholder}</FamilyText>
      </FamilyItem>
      <FamilyItem rotate={-33} top={26} left={21} onClick={() => postMessage(items[1])}>
        <FamilyText tac>{items[1]?.answers?.items?.[0]?.text || items[1]?.placeholder}</FamilyText>
      </FamilyItem>
      <FamilyItem rotate={30} top={33} right={25} onClick={() => postMessage(items[2])}>
        <FamilyText tac>{items[2]?.answers?.items?.[0]?.text || items[2]?.placeholder}</FamilyText>
      </FamilyItem>
      <FamilyItem rotate={68} bottom={26} right={6} onClick={() => postMessage(items[3])}>
        <FamilyText tac>{items[3]?.answers?.items?.[0]?.text || items[3]?.placeholder}</FamilyText>
      </FamilyItem>
      <FamilyItem rotate={-48} bottom={49} left={14} onClick={() => postMessage(items[4])}>
        <FamilyText tac>{items[4]?.answers?.items?.[0]?.text || items[4]?.placeholder}</FamilyText>
      </FamilyItem>
      <FamilyItem rotate={53} bottom={34} right={22} onClick={() => postMessage(items[5])}>
        <FamilyText tac>{items[5]?.answers?.items?.[0]?.text || items[5]?.placeholder}</FamilyText>
      </FamilyItem>
      <FamilyItem bottom={43} left={41.5} onClick={() => postMessage(items[6])}>
        <FamilyText tac>{items[6]?.answers?.items?.[0]?.text || items[6]?.placeholder}</FamilyText>
      </FamilyItem>
      <FamilyItem bottom={30} left={41.5} onClick={() => postMessage(items[7])}>
        <FamilyText tac>{items[7]?.answers?.items?.[0]?.text || items[7]?.placeholder}</FamilyText>
      </FamilyItem>
      <StyledHeading tac onClick={() => postMessage(items[8])}>
        {items[8]?.answers?.items?.[0]?.text || items[8]?.placeholder}
      </StyledHeading>
    </Container>
  );
};

export default FamilyTree;
